import React from 'react';

const MenuPage =(props) =>{
    return (
      <> 
      <div>
        <img src ={props.menuPage} alt ={props.menuPage} className="Menu"/> 
      </div>
      </>
    )
  }
  
  const Marassi = () => {
    return (
      <div className="App">
                <MenuPage menuPage = "/menupics/1.png" />
                <MenuPage menuPage = "/menupics/2sahel.png" />
                <MenuPage menuPage = "/menupics/3sahel.png" />
                <MenuPage menuPage = "/menupics/4sahel.png" />
                <MenuPage menuPage = "/menupics/5sahel.png" />
                <MenuPage menuPage = "/menupics/6sahel.png" />
                <MenuPage menuPage = "/menupics/7sahel.png" />
                <MenuPage menuPage = "/menupics/8sahel.png" />
                <MenuPage menuPage = "/menupics/9sahel.png" />
                <MenuPage menuPage = "/menupics/10sahel.png" />
      </div>
    );
  }
  export default Marassi;
  
  