import React from 'react';

const MenuPage =(props) =>{
    return (
      <> 
      <div>
        <img src ={props.menuPage} alt ={props.menuPage} className="Menu"/> 
      </div>
      </>
    )
  }
  
  const Madinaty = () => {
    return (
      <div className="App">
                <MenuPage menuPage = "/menupics/Highlights Menu -01.png" />
                <MenuPage menuPage = "/menupics/Highlights Menu -02.png" />
                <MenuPage menuPage = "/menupics/Highlights Menu -03.png" />
                <MenuPage menuPage = "/menupics/Highlights Menu -04.png" />
                <MenuPage menuPage = "/menupics/Highlights Menu -05.png" />
                <MenuPage menuPage = "/menupics/Highlights Menu -06.png" />
      </div>
    );
  }
  export default Madinaty;
  