import './App.css';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Marassi from './components/Marassi';
import Porto from './components/Porto';
import Madinaty from './components/Madinaty';

const MenuPage =(props) =>{
  return (
    <> 
    <div>
      <img src ={props.menuPage} alt ={props.menuPage} className="Menu"/> 
    </div>
    </>
  )
}

const MenuFull=() =>{
  return (
    
    <div className="App">
                <MenuPage menuPage = "/menupics/1.png" />
                <MenuPage menuPage = "/menupics/2.png" />
                <MenuPage menuPage = "/menupics/3.png" />
                <MenuPage menuPage = "/menupics/4.png" />
                <MenuPage menuPage = "/menupics/5.png" />
                <MenuPage menuPage = "/menupics/6.png" />
                <MenuPage menuPage = "/menupics/7.png" />
                <MenuPage menuPage = "/menupics/8.png" />
                <MenuPage menuPage = "/menupics/9.png" />
                <MenuPage menuPage = "/menupics/10.png" />
    </div>
  )
}

const App = () => {
  return (

    <div>
    <BrowserRouter>
      <Routes>
      <Route path="Marassi" element={<Marassi/>}></Route>  
      <Route path="Porto" element={<Porto/>}></Route> 
      <Route path="Sahel" element={<Porto/>}></Route> 
      <Route path="Madinaty" element={<Madinaty/>}></Route> 
      <Route path="/" element={<MenuFull/>}></Route>
      </Routes>
    </BrowserRouter>
    </div>
  );
}
export default App;
